import { FontType } from './font-type';

export const DEFAULT_PERCENTAGE_STEP = 0.25;

/**
 * Rules for student card settings.
 * * step - setting percentage step. E.g.; when `letterSpacing` setting value is `2`, the resulting percentage is `2 * <step>`
 */
export const CARD_SETTINGS_RULES = Object.freeze({
  letterSpacing: {
    step: 1,
    optionsAmount: 9,
    unit: 'px',
    default: 0,
  },

  lineHeight: {
    step: 0.2,
    optionsAmount: 6,
    unit: '',
    default: 1.5,
  },

  wordSpacing: {
    step: 5,
    optionsAmount: 5,
    unit: 'px',
    default: 0,
  },

  fontSize: {
    step: 0,
    optionsAmount: 2,
    unit: '',
    default: 0,
  },
});

export const cardSettingsRulesMixin = {
  created() {
    this.DEFAULT_PERCENTAGE_STEP = DEFAULT_PERCENTAGE_STEP;
    this.CARD_SETTINGS_RULES = CARD_SETTINGS_RULES;
  },

  methods: { getCardSettingCssValue, getFontTypeCssValue, parseFontSize },
};

/**
 * Get the modifier value for a given card settings key and value.
 * @param {String} key
 * @param {Number} key
 * @returns {Number} modifier value
 * @example getCardSettingCssValue('letterSpacing', 2); // => "3px"
 */
export function getCardSettingCssValue(key, step) {
  const rule = CARD_SETTINGS_RULES[key];
  const result = rule.default + rule.step * step;
  return result + rule.unit;
}

const FONT_SIZE_MAP = Object.freeze({
  0: 'regular',
  1: 'large',
  regular: 'regular',
  large: 'large',
});

export function parseFontSize(size = 0) {
  const result = FONT_SIZE_MAP[size];
  console.log('parse font size', size, result);
  return result;
}

export function getFontTypeCssValue(type) {
  switch (type) {
    case FontType.ARIAL:
      return 'Arial, Helvetica, sans-serif';

    case FontType.NUNITO:
      return '"Nunito", Helvetica, Arial, sans-serif';

    case FontType.COMIC_SANS:
      return '"Comic Neue", Comic Sans, Chalkboard, cursive';

    case FontType.OPEN_DYSLEXIC:
      return '"Open Dyslexic", Helvetica, Arial, sans-serif';

    case FontType.CAMBRIA:
      return '"Merriweather", Times Roman, Times New Roman, serif';

    default:
      return '"Nunito", Helvetica, Arial, sans-serif';
  }
}
