/*
 * A touch state monitor that you can use in your JS/TS project.
 *
 * This module is the compiled ESNext result of touch-state.ts
 *
 * This module uses GSAP. Check our notion docs for instructions @ https://www.notion.so/stijlbreuk/GSAP-6c9aa935a751474c9c40f00c5942fd51
 *
 * If you do not want to use GSAP for some reason, you can easily replace references to it by a solution of your choice.
 *
 * For example, you could write your own requestAnimationFrame loop to replace the gsap ticker.
 *
 * @author Stef van Wijchen <stef@stijlbreuk.nl>
 * @link https://gitlab.com/stijlbreuk/snippets/-/edit/main/touch-state.ts
 *
 * Created at     : 21-7-2021
 * Last modified  : 21-7-2021
 */

export var TouchDirection;
(function (TouchDirection) {
  TouchDirection['Up'] = 'up';
  TouchDirection['Right'] = 'right';
  TouchDirection['Down'] = 'down';
  TouchDirection['Left'] = 'left';
})(TouchDirection || (TouchDirection = {}));

/**
 * Monitor touch and pointer movements of an HTML element.
 * * Pointer events like mouse or touch are normalized to position and direction values
 * * Monitors single touch/pointer
 * @param element element to monitor pointer events of
 */
export class TouchState {
  constructor(element, useCapture = false) {
    this.element = element;
    this.useCapture = useCapture;
    this.startListeners = [];
    this.moveListeners = [];
    this.endListeners = [];
    this.mouseDown = false;
    this.touching = false;
    this.verticalVelocity = 0;
    this.horizontalVelocity = 0;

    this.addEventListener = (event, listener) => {
      const key = `${event}Listeners`;
      this[key].push(listener);
    };

    this.handleTouchStart = (event) => {
      this.event = event;
      if (isMouseEvent(event)) this.mouseDown = true;
      if (isTouchEvent(event)) this.touching = true;
      this.initialTouch = this.getEventPosition(event);
      this.startListeners.forEach((e) => e(this, event));
    };

    this.handleTouchMove = (event) => {
      this.event = event;
      this.previousTouch = this.currentTouch;
      const position = this.getEventPosition(event);
      if (!position) return;
      if (
        isTouchEvent(event) ||
        (isMouseEvent(event) && this.mouseDown === true)
      ) {
        this.currentTouch = position;
      }

      this.moveListeners.forEach((e) => e(this, event));
    };

    this.handleTouchEnd = (event) => {
      this.event = event;
      // reset
      this.endListeners.forEach((e) => e(this, event));
      this.mouseDown = false;
      this.touching = false;
      this.initialTouch = undefined;
      this.currentTouch = undefined;
      // cleanup
      const isLive = document.body.contains(this.element);
      if (isLive) return;
      this.element?.remove();
      delete this.element;
    };

    this.setVelocities = () => {
      if (!this.initialTouch || !this.currentTouch) return;
      this.horizontalVelocity = this.getVelocity('x');
      this.verticalVelocity = this.getVelocity('y');
    };

    this.attachEventListeners();
  }

  /** Pointer active (mouse down / touching) */
  get active() {
    return this.mouseDown || this.touching;
  }

  /** Horizontal state */
  get horizontal() {
    const self = this;
    return {
      /** Absolute horizontal distance moved from start */
      get distance() {
        return self.horizontalDistance;
      },

      /** Pointer's horizontal movement direction */
      get direction() {
        return self.horizontalDirection;
      },

      /** velocity in pixels/second */
      get velocity() {
        return self.horizontalVelocity;
      },
    };
  }

  /** Vertical state */
  get vertical() {
    const self = this;
    return {
      /** Absolute vertical distance moved from start */
      get distance() {
        return self.verticalDistance;
      },

      /** Pointer's vertical movement direction */
      get direction() {
        return self.verticalDirection;
      },

      /** velocity in pixels/second */
      get velocity() {
        return self.verticalVelocity;
      },
    };
  }

  get horizontalDirection() {
    if (!this.initialTouch || !this.currentTouch) return undefined;
    const a = this.initialTouch.x;
    const b = this.currentTouch.x;
    return a === b
      ? undefined
      : a < b
      ? TouchDirection.Right
      : TouchDirection.Left;
  }

  get horizontalDistance() {
    if (!this.initialTouch || !this.currentTouch) return 0;
    const a = this.initialTouch.x;
    const b = this.currentTouch.x;
    return Math.abs(a - b);
  }

  get verticalDirection() {
    if (!this.initialTouch || !this.currentTouch) return undefined;
    const a = this.initialTouch.y;
    const b = this.currentTouch.y;
    return a === b
      ? undefined
      : a < b
      ? TouchDirection.Down
      : TouchDirection.Up;
  }

  get verticalDistance() {
    if (!this.initialTouch || !this.currentTouch) return 0;
    const a = this.initialTouch.y;
    const b = this.currentTouch.y;
    return Math.abs(a - b);
  }

  /**
   * set the target element to listen for pointer events
   * @param element
   */
  setElement(element) {
    if (this.element) throw new Error('Touch element already set.');
    this.element = element;
    this.attachEventListeners();
  }

  removeEventListener(event, listener) {
    const key = `${event}Listeners`;
    this[key] = this[key].filter((f) => f !== listener);
  }

  /**
   * Get velocity between initial and current touch in pixels per second
   * @param axis axis of movement velocity to measure
   */
  getVelocity(axis) {
    const start = this.previousTouch ?? this.currentTouch;
    const end = this.currentTouch;
    if (!end || !start) return 0;
    const a = start[axis];
    const b = end[axis];
    const distance = Math.abs(a - b);
    const time = end.time - start.time;
    const velocity = distance / (time / 1000);
    if (isNaN(velocity) || velocity === Infinity) {
      return 0;
    }
    return velocity;
  }

  getEventPosition(event) {
    if (isTouchEvent(event)) {
      const touch = event.touches[0];

      if (!touch) return;

      return {
        x: touch.clientX,
        y: touch.clientY,
        time: event.timeStamp,
      };
    }

    return {
      x: event.clientX,
      y: event.clientY,
      time: event.timeStamp,
    };
  }

  attachEventListeners() {
    const e = this.element;
    // pointer start events
    e?.addEventListener('touchstart', this.handleTouchStart, this.useCapture);
    e?.addEventListener('mousedown', this.handleTouchStart, this.useCapture);
    // pointer move events
    e?.addEventListener('touchmove', this.handleTouchMove, this.useCapture);
    e?.addEventListener('mousemove', this.handleTouchMove, this.useCapture);
    // pointer end events
    e?.addEventListener('touchend', this.handleTouchEnd, this.useCapture);
    window.addEventListener('mouseup', this.handleTouchEnd, this.useCapture);
    // velocity ticker
    gsap.ticker.add(this.setVelocities);
  }

  destroy() {
    const e = this.element;
    // pointer start events
    e?.removeEventListener('touchstart', this.handleTouchStart);
    e?.removeEventListener('mousedown', this.handleTouchStart);
    // pointer move events
    e?.removeEventListener('touchmove', this.handleTouchMove);
    e?.removeEventListener('mousemove', this.handleTouchMove);
    // pointer end events
    e?.removeEventListener('touchend', this.handleTouchEnd);
    window.removeEventListener('mouseup', this.handleTouchEnd);
    // velocity ticker
    gsap.ticker.remove(this.setVelocities);
    // own events
    this.startListeners = [];
    this.moveListeners = [];
    this.endListeners = [];
  }
}

function isTouchEvent(event) {
  return window.TouchEvent && event instanceof TouchEvent;
}

function isMouseEvent(event) {
  return window.MouseEvent && event instanceof MouseEvent;
}
