<template>
  <i-select v-bind="$attrs" :value="value" @input="$emit('input', $event)">
    <i-option v-for="number in optionsAmount" :key="number" :value="number - 1">
      {{ getOptionLabel(number - 1) }}
    </i-option>
  </i-select>
</template>

<script>
import { DEFAULT_PERCENTAGE_STEP } from '@/lib/card-settings-rules';

const DEFAULT_QUANTITY_OPTIONS_AMOUNT = 5;
const DEFAULT_MINIMUM = 1;
const DEFAULT_STEP = DEFAULT_PERCENTAGE_STEP;

export default {
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    optionsAmount: {
      type: Number,
      default: DEFAULT_QUANTITY_OPTIONS_AMOUNT,
    },
    step: {
      type: Number,
      default: DEFAULT_STEP,
    },
    min: {
      type: Number,
      default: DEFAULT_MINIMUM,
    },
    type: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    getOptionLabel(number) {
      const max = this.optionsAmount * this.step;

      switch (this.type) {
        case 'fontSize': {
          return ['Standaard', 'Groot'][number];
        }

        default:
          return number === 0 ? 'Standaard' : number;
      }
    },
  },
};
</script>
