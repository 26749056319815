<template>
  <swipe-handler @swiperight="handleSwipeRight" @swipeleft="handleSwipeLeft">
    <div class="sb_session_practise-card" :class="{ 'print-mode': printMode }">
      <div class="sb_session_practise-card_container">
        <div class="sb_session_practise-card_content">
          <slot></slot>
        </div>
        <div class="sb_session_practise-card_bottom">
          <div class="sb_session_practise-card_bottom_left">
            <slot name="bottom-left"> </slot>
          </div>
          <div class="sb_session_practise-card_bottom_center">
            <div class="sb_session_practise-card_steps-and-card-name">
              <div class="sb_session_practise-card_steps-and-card-name_steps">
                {{ currentStep }}/{{ totalSteps }}
              </div>
              <div
                v-if="cardName"
                class="sb_session_practise-card_steps-and-card-name_card-name"
              >
                {{ cardName }}
              </div>
            </div>
          </div>
          <div class="sb_session_practise-card_bottom_right">
            <slot name="bottom-right"> </slot>
          </div>
        </div>
      </div>
    </div>
  </swipe-handler>
</template>

<script>
import SwipeHandler from '@/components/SwipeHandler.vue';
export default {
  components: { SwipeHandler },

  props: {
    printMode: { type: Boolean, default: false },

    currentStep: {
      type: Number,
      default: 1,
    },

    totalSteps: {
      type: Number,
      default: 1,
    },

    cardName: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return { accumulatedNoOfLines: new Array(this.totalSteps).fill(0) };
  },

  methods: {
    handleSwipeRight() {
      this.$emit('swiperight');
    },

    handleSwipeLeft() {
      this.$emit('swipeleft');
    },
  },
};
</script>

<style lang="scss">
.sb_session_practise-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 810px;
  counter-reset: sentences;

  &.print-mode {
    position: relative;
    top: unset;
    left: unset;
    height: unset;
    min-height: 910px;
    margin: 0 auto calc(100vh - 910px) auto;

    @media print {
      // Start on first page #34687167
      /* page-break-before: always; */
    }
  }

  //
  //  CONTAINER
  //
  &_container {
    display: grid;
    grid-template-rows: 810px 100px;

    @media print {
      grid-template-rows: auto;
    }
  }

  //
  // CONTENT
  //
  &_content {
    box-shadow: 0 0 0 2px $brand-light-gray;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media print {
      box-shadow: none;
    }
  }

  //
  // BOTTOM
  //
  &_bottom {
    word-spacing: initial;
    letter-spacing: initial;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 1rem;
    background: $brand-lightest-gray;
    box-shadow: 0 0 0 2px $brand-light-gray;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media print {
      border: none;
      box-shadow: none;
      background: transparent;
    }

    //
    // BOTTOM - LEFT
    //
    &_left {
      display: flex;
      button {
        margin: 0 0.5rem 0 0;
      }
      @media print {
        visibility: hidden;
      }
    }
    //
    // BOTTOM - CENTER
    //
    &_center {
      position: absolute;
      left: 50%;
      top: 50%;
      max-width: 800px;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
    }
    //
    // BOTTOM - right
    //
    &_right {
      display: flex;
      button {
        margin: 0 0 0 0.5rem;
      }

      @media print {
        visibility: hidden;
      }
    }
  }

  //
  // STEPS-AND-CARD-NAME
  //
  &_steps-and-card-name {
    display: flex;
    //
    // STEPS
    //
    &_steps {
      font-weight: $bold;
      color: $brand-gray;
      padding: 0 2rem 0 0;
    }

    //
    // CARD-NAME
    //
    &_card-name {
      font-weight: $bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: rem-calc(800);
    }
  }

  &_featured-image {
    pointer-events: none;
    right: 0;
    top: 0;
    width: 25%;
    max-width: 25%;
    position: absolute;
    height: 100%;

    &_img {
      position: absolute;
      height: calc(100% - 2rem);
      width: calc(100% - 4rem);
      right: 2rem;
      bottom: 1rem;
      object-fit: contain;
      object-position: bottom;
    }
  }
}
</style>
