export const FontType = {
  ARIAL: 'ARIAL',
  NUNITO: 'NUNITO',
  COMIC_SANS: 'COMIC_SANS',
  OPEN_DYSLEXIC: 'OPEN_DYSLEXIC',
  CAMBRIA: 'CAMBRIA',
};

/**
 * Get a human readable label for the corresponding `FontType` member.
 * @param {String} type
 * @returns {String} font type label
 */
export function getFontTypeLabel(type) {
  if (!FontType[type]) throw new Error(`Uknown type ${type}`);
  if (type === FontType.COMIC_SANS) return 'Comic Neue';
  if (type === FontType.CAMBRIA) return 'Merriweather';
  return type
    .split('_')
    .map((word) => word[0] + word.substr(1).toLowerCase())
    .join(' ');
}

export const fontTypeMixin = {
  data: () => ({ FontType }),
  methods: { getFontTypeLabel },
};
