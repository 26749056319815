<template>
  <i-select
    :value="value"
    placeholder="Lettertype"
    :style="value ? `font-family: ${getFontTypeCssValue(value)}` : undefined"
    @input="$emit('input', $event)"
  >
    <i-option
      v-for="font in FontType"
      :key="font"
      :value="font"
      :style="`font-family: ${getFontTypeCssValue(font)}`"
    >
      {{ getFontTypeLabel(font) }}
    </i-option>
  </i-select>
</template>

<script>
import { fontTypeMixin } from '@/lib/font-type';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
export default {
  mixins: [fontTypeMixin, cardSettingsRulesMixin],
  props: { value: { type: String, default: undefined } },
};
</script>
