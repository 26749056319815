<template>
  <div class="sb_cards-session_training_instructions">
    <div
      class="sb_cards-session_training_instructions_background"
      :class="{ s_toggled: value }"
      @click="$emit('input', !value)"
    />
    <div
      class="sb_cards-session_training_instructions_inner"
      :class="{ s_toggled: value }"
    >
      <sb-spacer height="20" />
      <div class="sb_cards-session_training_instructions_inner_content">
        <sb-spacer height="20" />
        <sb-title text="Instellingen" size="regular" />
        <sb-spacer height="20" />

        <i-form>
          <div class="flex">
            <form-item label="Toon illustraties">
              <i-switch
                :value="Boolean(formData.showIllustrations)"
                @input="formData.showIllustrations = $event"
              >
                <span slot="open">Ja</span>
                <span slot="close">Nee</span>
              </i-switch>
            </form-item>

            <form-item label="Toon regelnummers">
              <i-switch
                :value="Boolean(formData.lineNumbers)"
                @input="formData.lineNumbers = $event"
              >
                <span slot="open">Ja</span>
                <span slot="close">Nee</span>
              </i-switch>
            </form-item>
          </div>

          <form-item label="Lettertype" class="lettertype">
            <sb-font-select v-model="formData.fontType" />
          </form-item>

          <form-item label="Lettergrootte">
            <sb-student-settings-quantity-select
              v-model="formData.fontSize"
              placeholder="Lettergrootte"
              type="fontSize"
              :min="0"
              :max="1"
              :step="CARD_SETTINGS_RULES.fontSize.step"
              :options-amount="CARD_SETTINGS_RULES.fontSize.optionsAmount"
            />
          </form-item>

          <form-item label="Letterafstand">
            <sb-student-settings-quantity-select
              v-model="formData.letterSpacing"
              placeholder="Letterafstand"
              :min="0"
              :step="CARD_SETTINGS_RULES.letterSpacing.step"
              :options-amount="CARD_SETTINGS_RULES.letterSpacing.optionsAmount"
            />
          </form-item>

          <form-item label="Regelafstand">
            <sb-student-settings-quantity-select
              v-model="formData.lineHeight"
              placeholder="Regelafstand"
              :min="1"
              :step="CARD_SETTINGS_RULES.lineHeight.step"
              :options-amount="CARD_SETTINGS_RULES.lineHeight.optionsAmount"
            />
          </form-item>

          <form-item label="Woordafstand">
            <sb-student-settings-quantity-select
              v-model="formData.wordSpacing"
              placeholder="Woordafstand"
              :step="CARD_SETTINGS_RULES.wordSpacing.step"
              :options-amount="CARD_SETTINGS_RULES.wordSpacing.optionsAmount"
            />
          </form-item>
        </i-form>
        <!-- End paste -->
      </div>

      <div
        v-if="!!value && !$store.state.doShowWordDefinitionInfo"
        class="sb_cards-session_training_instructions_label"
        @click="$emit('input', !value)"
      >
        <sb-icon icon-id="icon_caret-right" />
      </div>
    </div>
  </div>
</template>

<script>
import SbStudentSettingsQuantitySelect from '@/components/SbStudentSettingsForm/SbStudentSettingsQuantitySelect.vue';
import SbFontSelect from '@/components/SbFontSelect.vue';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { fontTypeMixin } from '@/lib/font-type';
import gql from 'graphql-tag';
export default {
  name: 'SbCardsSessionSettingsDrawer',

  components: { SbStudentSettingsQuantitySelect, SbFontSelect },

  mixins: [cardSettingsRulesMixin, fontTypeMixin],

  props: {
    value: { type: Boolean, required: true },
    card: { type: Object, default: undefined },
    studentId: { type: String, default: undefined },
  },

  inject: ['settings'],

  data() {
    return {
      formData: {
        cursorSpeed: undefined,
        focusMode: undefined,
        fontType: undefined,
        fontSize: undefined,
        letterSpacing: undefined,
        lineHeight: undefined,
        wordSpacing: undefined,
        lineNumbers:
          this.$route.name === 'SessionCardPrint'
            ? this.$route.query.lineNumbers === 'true'
            : false,
        showIllustrations:
          this.$route.name === 'SessionCardPrint'
            ? this.$route.query.showIllustrations === 'true'
            : true,
      },
    };
  },

  computed: {
    userId() {
      if (this.studentId) {
        // Means your in coach session.
        return this.studentId;
      } else {
        // Means your in Kaartenbak. Use current logged in user.
        return this.$user.id;
      }
    },
  },

  watch: {
    formData: {
      handler(value) {
        Object.entries(value).forEach(([key, value]) => {
          this.settings[key] = value;
        });
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.getUserSettings();
    this.$watch(
      'formData',
      function (value) {
        if (this.showChangeNotifications) {
          this.$Message.success('Instellingen aangepast');
        }

        this.$store.state.doShowLineNumbers = Boolean(value.lineNumbers);
        this.$store.state.lineHeight = value.lineHeight;
        this.$store.state.letterSpacing = value.letterSpacing;
        this.$store.state.fontValue = value.fontSize;
        this.$store.state.fontValue = value.fontSize;
      },
      { deep: true },
    );
  },

  methods: {
    async getUserSettings() {
      if (!this.userId) return;

      try {
        const { data, errors } = await this.$apollo.query({
          fetchPolicy: 'network-only',
          query: gql`query GetSettingsByUserId {
          getUserById(id: "${this.userId}") {
            id
            cardSettings {
              fontType
              fontSize
              letterSpacing
              lineHeight
              wordSpacing
              cursorSpeed
              focusMode
              showIllustrations
              lineNumbers
            }
          }
        }`,
        });

        if (errors) {
          return this.$showGenericError();
        }

        Object.entries(data.getUserById?.cardSettings ?? {}).forEach(
          ([key, value]) => {
            this.formData[key] = value;
          },
        );

        this.$nextTick().then(() => (this.showChangeNotifications = true));
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_training_instructions {
  position: fixed;
  width: 0;
  right: 0;
  top: 0;
  height: 100vh;
  overflow: visible;
  z-index: 1000;

  // for related print settings on the <body>, check app.scss
  @media print {
    display: none;
  }

  &_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    transition: 0.3s background-color;
    z-index: 0;
    pointer-events: none;

    &.s_toggled {
      background: rgba($brand-black, 0.5);
      pointer-events: unset;
    }
  }

  &_inner {
    width: 480px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(calc(100% - 8px));
    background: $brand-white;
    overflow: visible;
    padding: 40px;
    transition: 0.3s transform, 0.3s box-shadow;

    &_content {
      overflow: auto;
      white-space: pre-line;
    }

    &.s_toggled {
      transform: translateX(-8px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  &_label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(calc(-100% + 8px), -50%);
    z-index: -1;
    padding-right: 8px;
    width: 48px;
    height: 60px;
    background: $brand-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $brand-primary;
    border-top-left-radius: $default-border-radius;
    border-bottom-left-radius: $default-border-radius;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &:hover {
      color: $brand-black;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      transition-duration: 0s;
    }

    &::after {
      content: ' ';
      position: absolute;
      right: -12px;
      width: 20px;
      height: 80px;
      top: -10px;
      background: $brand-white;
    }
  }
}
</style>

<style lang="scss">
.lettertype {
  .ivu-select-dropdown {
    max-height: 292px !important;
  }
}
</style>
