<template>
  <div class="sb_session_scaled-content">
    <div
      class="sb_session_scaled-content_container"
      :style="{
        transform: `translate(-50%, ${'calc(-50% - 10px)'}) scale(${scale})`,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['fullscreen'],

  props: {
    previewMode: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      scale: 1,
      fullscreen: this.fullscreen || { toggled: true },
    };
  },

  mounted() {
    this.$watch(
      () => this.fullscreen?.toggled,
      () => this.$nextTick(() => this.setbackgroundScale()),
    );

    this.resizeObserver = new ResizeObserver(
      () => void this.setbackgroundScale(),
    );

    this.resizeObserver.observe(this.$el);
    this.$nextTick(() => this.setbackgroundScale());
  },

  methods: {
    setbackgroundScale() {
      const nativeWidth = 1440;
      const nativeHeight = 910;
      const { width, height } = this.$el.getBoundingClientRect();
      const paddingX = 10;
      const paddingY = 50;

      this.scale = Math.min(
        (width - paddingX) / nativeWidth,
        (height - paddingY) / nativeHeight,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_session_scaled-content {
  position: relative;
  width: 100%;
  height: 100%;

  &:fullscreen {
    width: 100vw;
    height: 100vh;
    background: #fff;
  }

  //
  //  CONTAINER
  //
  &_container {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    width: 1440px;
    height: 910px;

    @media print {
      transform: none !important;
      transform-origin: unset;
      top: unset;
      left: unset;
      position: unset;
      width: unset;
      height: unset;
    }
  }
}
</style>
