<template>
  <div
    class="swipe-container"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    };
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    },
    handleTouchEnd() {
      const deltaX = this.endX - this.startX;
      const deltaY = this.endY - this.startY;

      // Set a threshold for swipe detection
      const threshold = 150;

      if (Math.abs(deltaX) > threshold) {
        if (deltaX > 0) {
          // Swipe right
          this.$emit('swiperight');
        } else {
          // Swipe left
          this.$emit('swipeleft');
        }
      } else if (Math.abs(deltaY) > threshold) {
        if (deltaY > 0) {
          // Swipe down
          this.$emit('swipedown');
        } else {
          // Swipe up
          this.$emit('swipeup');
        }
      }
    },
  },
};
</script>

<style scoped>
.swipe-container {
  /* Add your styles here */
}
</style>
