var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sb_session-card_type-5",class:( _obj = {}, _obj[_vm.WORD_CONTAINER_TAG] = true, _obj ),style:({ padding: _vm.calculateContentPadding() }),attrs:{"font-size":_vm.parseFontSize(
      _vm.settings.fontSize != undefined
        ? _vm.settings.fontSize
        : _vm.object.settings.fontSize
    ),"image-position":_vm.object.settings.featuredImagePosition,"image-alignment":_vm.object.settings.featuredImageAlignment,"content-image-ratio":_vm.object.settings.contentImageRatio}},[_c('div',{staticClass:"sb_session-card_type-5_inner"},[_c('div',{staticClass:"sb_session-card_type-5_items",style:({
        gridTemplateColumns: ("repeat(" + _vm.columnsAmount + ", 1fr)"),
        margin: _vm.columnsAmount <= 3 ? '0 auto' : undefined,
        columnGap: _vm.columnsAmount <= 3 ? '90px' : undefined,
      })},_vm._l((_vm.items),function(item,i){return _c('div',{key:("item-" + i),staticClass:"sb_session-card_type-5_item"},[_c('div',{staticClass:"sb_session-card_type-5_item_text"},[_vm._v(" "+_vm._s(item)+" ")]),_c('div',{staticClass:"sb_session-card_type-5_item_image"},[(_vm.object.content.pronunciationIcon)?_c('img',{staticClass:"sb_session-card_type-5_item_image_img",attrs:{"src":_vm.object.content.pronunciationIcon,"alt":""}}):_vm._e()])])}),0)]),(_vm.object.content.featuredImage && _vm.showIllustrations)?_c('div',{staticClass:"sb_session-card_type-5_image"},[_c('img',{staticClass:"sb_session-card_type-5_image_img",attrs:{"src":_vm.object.content.featuredImage,"alt":""}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }