export class AudioController {
  constructor() {
    this.audio = undefined;
    this.playing = undefined;
    this.loadStateHandlers = new Set();
    this.playStateHandlers = new Set();
    this.tracks = new Map();
    this.readyStates = new Map();
    this.handleLoadEvent = this.handleLoadEvent.bind(this);
  }

  load(...uris) {
    uris.forEach((uri) => {
      this.audio = new Audio();
      this.audio.preload = 'auto';
      this.audio.src = uri;
      this.tracks.set(uri, this.audio);
      this.readyStates.set(uri, this.audio.readyState);

      [
        'loadstart',
        'durationchange',
        'loadedmetadata',
        'loadeddata',
        'progress',
        'canplay',
        'canplaythrough',
        'loadstart',
      ].forEach((event) =>
        this.audio.addEventListener(event, this.handleLoadEvent),
      );

      this.audio.addEventListener('ended', () =>
        this.playStateHandlers.forEach((handler) => handler(false)),
      );
    });
  }

  play(uri) {
    this.pause(false);
    this.tracks.get(uri)?.play();
    this.playing = uri;
    this.playStateHandlers.forEach((handler) => handler(true));
  }

  pause(emitPlayState = true) {
    this.tracks.forEach((audio) => audio.pause());
    this.playing = undefined;
    if (emitPlayState) {
      this.playStateHandlers.forEach((handler) => handler(false));
    }
  }

  updateReadyStates() {
    this.tracks.forEach((audio) =>
      this.readyStates.set(audio.src, audio.readyState),
    );

    this.loadStateHandlers.forEach((handler) =>
      handler(
        Array.from(this.tracks).every(([_, audio]) => audio.readyState === 4),
      ),
    );
  }

  handleLoadEvent() {
    this.updateReadyStates();
  }

  addLoadStateListener(callback) {
    this.loadStateHandlers.add(callback);
  }

  removeLoadStateListener(callback) {
    this.loadStateHandlers.delete(callback);
  }

  addPlayStateListener(callback) {
    this.playStateHandlers.add(callback);
  }

  removePlayStateListener(callback) {
    this.playStateHandlers.delete(callback);
  }
}
