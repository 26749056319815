<template>
  <div
    class="sb_cards-session_training"
    :class="{
      'sb_card-preview': previewMode,
      'sb_card-print': printMode,
      'v_reading-comprehension':
        doShowReadingComprehension && readingComprehension,
    }"
  >
    <sb-cards-session-settings-drawer
      v-model="showSettingsModal"
      :student-id="studentIds.length === 1 ? studentIds[0] : undefined"
      @word-definition-mode-change="wordDefinitionMode = $event"
    />

    <!-- NON PRINT MODE -->
    <sb-session-scaled-content v-if="!printMode" :preview-mode="previewMode">
      <div
        v-if="previewMode || fullscreen.toggled"
        class="sb_card-preview_navigation"
      >
        <i-button v-show="hasPreviousSlide" @click="PREVIEW_previousSlide">
          vorige
        </i-button>
        <div v-show="!hasPreviousSlide" style="width: 120px"></div>
        <i-button
          v-if="!(addFinishRemark ? false : !hasNextSlide)"
          :type="addFinishRemark && !hasNextSlide ? 'primary' : undefined"
          @click="PREVIEW_nextSlide"
        >
          {{
            addFinishRemark && !hasNextSlide ? 'Oefening afronden' : 'volgende'
          }}
        </i-button>
        <div
          v-show="!addFinishRemark && !hasNextSlide"
          style="width: 120px"
        ></div>
      </div>

      <template v-if="totalSlidesAmount > 0">
        <sb-session-practice-card
          :current-step="slideIndex + 1"
          :total-steps="totalSlidesAmount"
          :card-name="currentCardName"
          :style="{
            wordSpacing: cardCssSettings.wordSpacing,
            letterSpacing: cardCssSettings.letterSpacing,
            lineHeight: cardCssSettings.lineHeight,
            fontFamily: cardCssSettings.fontFamily,
            counterReset: 'training',
          }"
          @swipeleft="nextSlide"
          @swiperight="previousSlide"
        >
          <template v-for="number in CARDS_AMOUNT">
            <component
              :is="'sb-session-card-type' + number"
              v-if="
                slides[slideIndex].content.__typename ===
                'CardSlideContentType' + number
              "
              :key="`${number}-${slideIndex}`"
              :object="slides[slideIndex]"
              :all-slides="slides"
              :show-illustrations="settings.showIllustrations"
            />
          </template>

          <template v-show="!printMode" #bottom-left>
            <button
              v-if="slides[slideIndex].audioFile"
              id="CardAudioTempoButton"
              title="Audio met tempo. De audio van de tekst wordt met een regulier tempo afgespeeld."
              @click="
                audioPlaying === slides[slideIndex].audioFile
                  ? audioController.pause()
                  : audioController.play(slides[slideIndex].audioFile)
              "
            >
              <sb-button
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon
                  v-if="audioPlaying !== slides[slideIndex].audioFile"
                  icon-id="icon_audio-fast"
                />
                <icon v-else type="ios-pause" :size="20" />
              </sb-button>
            </button>

            <button
              v-if="slides[slideIndex].audioFileSlowTrack"
              id="CardAudioSlowButton"
              title="Audio wat rustiger. De audio van de tekst wordt met een rustige snelheid afgespeeld."
              @click="
                audioPlaying === slides[slideIndex].audioFileSlowTrack
                  ? audioController.pause()
                  : audioController.play(slides[slideIndex].audioFileSlowTrack)
              "
            >
              <sb-button
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon
                  v-if="audioPlaying !== slides[slideIndex].audioFileSlowTrack"
                  icon-id="icon_audio-slow"
                />
                <icon v-else type="ios-pause" :size="20" />
              </sb-button>
            </button>

            <button v-if="get(card, 'instruction')" @click="showInstructions">
              <sb-button
                id="CardInfoButton"
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon scale="1.1" icon-id="icon_info" />
              </sb-button>
            </button>

            <!-- Reading comprehension placement placeholder for Tour -->
            <div
              v-if="
                !get(platformSettings, 'readingComprehension') ||
                !get(card, 'addReadingComprehension')
              "
              id="CardReadingComprehensionButton"
            />

            <button
              v-if="
                get(platformSettings, 'readingComprehension') &&
                get(card, 'addReadingComprehension')
              "
              id="CardReadingComprehensionButton"
              class="flex flex-align-center flex-justify-end gap-1"
              @click="doShowReadingComprehension = !doShowReadingComprehension"
            >
              <sb-button
                class="reading-comprehension-btn"
                :class="{
                  s_active: doShowReadingComprehension,
                }"
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon scale="1.1" icon-id="icon_reading-comprehension" />
              </sb-button>
            </button>
          </template>

          <template #bottom-right>
            <div
              v-if="get(platformSettings, 'dictionary')"
              id="CardDictionaryButton"
              class="sb_cards-session_reading-comprehension-button"
            >
              <div class="flex flex-col gap-1">
                <div class="flex flex-align-center flex-justify-end gap-1">
                  Woordenboek
                  <i-switch
                    v-model="$store.state.doShowWordDefinitions"
                    @click.native.stop
                  />
                </div>
              </div>
            </div>

            <button @click="showSettingsModal = true">
              <sb-button
                id="CardSettingsButton"
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon icon-id="icon_cog" />
              </sb-button>
            </button>

            <!-- FullScreen Tour Placeholder -->
            <div v-if="printMode" id="CardFullScreenButton" />
            <button
              v-if="!printMode"
              id="CardFullScreenButton"
              @click="toggleFullScreen"
            >
              <sb-button
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <icon
                  :type="fullscreen.toggled ? 'md-contract' : 'md-expand'"
                  :size="20"
                />
              </sb-button>
            </button>

            <button @click="printCard">
              <sb-button
                id="CardPrintButton"
                button-type="light-black"
                icon-size="large"
                round
                ghost
              >
                <sb-icon icon-id="icon_print" />
              </sb-button>
            </button>
          </template>
        </sb-session-practice-card>
      </template>
    </sb-session-scaled-content>

    <sb-reading-comprehension-column
      v-if="
        get(platformSettings, 'readingComprehension') &&
        doShowReadingComprehension &&
        !!readingComprehension
      "
      :data-print="isPrintMode"
      @close="doShowReadingComprehension = false"
    >
      <sb-reading-comprehension :reading-comprehension="readingComprehension" />
    </sb-reading-comprehension-column>

    <!-- PRINT MODE -->
    <div v-if="printMode" class="sb_print-card">
      <div class="sb_print-button-container">
        <p>
          {{ get(card, 'title') || 'Kaart ophalen...' }}
        </p>
        <i-button
          type="primary"
          size="large"
          :disabled="loadingPrintContent"
          @click="printCard"
        >
          {{ loadingPrintContent ? 'Voorbereiden...' : 'Kaart afdrukken' }}
        </i-button>
      </div>

      <div v-if="totalSlidesAmount > 0" class="sb_print-card_slides">
        <sb-session-practice-card
          v-for="(slide, printIndex) in slides"
          :key="slide.id"
          :current-step="printIndex + 1"
          :total-steps="totalSlidesAmount"
          :card-name="getCardNameByIndex(printIndex)"
          :style="{
            wordSpacing: cardCssSettings.wordSpacing,
            letterSpacing: cardCssSettings.letterSpacing,
            lineHeight: cardCssSettings.lineHeight,
            fontFamily: cardCssSettings.fontFamily,
          }"
          print-mode
          @swipeleft="nextSlide"
          @swiperight="previousSlide"
        >
          <template v-for="number in CARDS_AMOUNT">
            <component
              :is="'sb-session-card-type' + number"
              v-if="
                slides[printIndex].content.__typename ===
                'CardSlideContentType' + number
              "
              :key="`${number}-${printIndex}`"
              :object="slides[printIndex]"
              :show-illustrations="settings.showIllustrations"
            />
          </template>
        </sb-session-practice-card>

        <div class="reading-comprehension-print">
          <sb-reading-comprehension
            v-if="
              get(platformSettings, 'readingComprehension') &&
              doShowReadingComprehension &&
              !!readingComprehension
            "
            :reading-comprehension="readingComprehension"
          />
        </div>
        <div class="sb_print-spacer" />
      </div>
    </div>

    <sb-cards-session-meta-modal
      v-if="!printMode"
      v-model="showMetaModal"
      :card="card"
    />

    <div
      v-if="
        get(platformSettings, 'readingComprehension') &&
        !doShowReadingComprehension &&
        !!readingComprehension
      "
      class="sb_cards-session_reading-comprehension_toggle"
      @click="doShowReadingComprehension = true"
    >
      <sb-icon icon-id="icon_reading-comprehension" />
    </div>

    <button v-if="showCloseButton" class="close-button" @click="handleClose">
      <sb-icon icon-id="icon_close" style="color: red" />
    </button>
  </div>
</template>

<script>
import { AudioController } from '@/lib/audio-controller';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { createSessionCardQuery } from './create-session-card-query';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import SbCardsSessionMetaModal from './SbCardsSessionMetaModal.vue';
import SbCardsSessionSettingsDrawer from './SbCardsSessionSettingsDrawer.vue';
import SbSessionCardType1 from '@/components/session/SbSessionCardType1';
import SbSessionCardType2 from '@/components/session/SbSessionCardType2';
import SbSessionCardType3 from '@/components/session/SbSessionCardType3';
import SbSessionCardType4 from '@/components/session/SbSessionCardType4';
import SbSessionCardType5 from '@/components/session/SbSessionCardType5';
import SbSessionCardType6 from '@/components/session/SbSessionCardType6';
import SbSessionCardType7 from '@/components/session/SbSessionCardType7';
import SbSessionCardType8 from '@/components/session/SbSessionCardType8';
import SbSessionPracticeCard from '@/components/session/SbSessionPracticeCard';
import SbSessionScaledContent from '@/components/session/SbSessionScaledContent';
import { objectOmit } from '@/lib/object-omit';
import SbReadingComprehensionColumn from './SbReadingComprehensionColumn.vue';
import SbWordDefinition from '@/components/SbWordDefinition.vue';
import SbReadingComprehension from './SbReadingComprehension.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SbCardsSessionTraining',

  components: {
    SbSessionScaledContent,
    SbSessionPracticeCard,
    SbSessionCardType1,
    SbSessionCardType2,
    SbSessionCardType3,
    SbSessionCardType4,
    SbSessionCardType5,
    SbSessionCardType6,
    SbSessionCardType7,
    SbSessionCardType8,
    SbCardsSessionMetaModal,
    SbCardsSessionSettingsDrawer,
    SbReadingComprehensionColumn,
    SbWordDefinition,
    SbReadingComprehension,
  },

  inject: ['fullscreen'],

  mixins: [
    platformSettingsMixin,
    cardSettingsRulesMixin,
    nonReactiveMembersMixin(
      () => ({
        CARDS_AMOUNT,
        audioController: new AudioController(),
      }),
      (self) => {
        self.audioController.addLoadStateListener(
          (ready) => (self.audioLoaded = ready),
        );

        self.audioController.addPlayStateListener((playing) => {
          self.audioPlaying = self.audioController.playing;
        });
      },
    ),
  ],

  data() {
    const _fullscreen = this.fullscreen
      ? {}
      : { fullscreen: { toggled: false } };

    return {
      ..._fullscreen,
      doShowReadingComprehension: false,
      wordDefinitionMode: false,
      showSettingsModal: false,
      showMetaModal: false,
      audioLoaded: false,
      audioPlaying: false,
      cardSettings: undefined,
      loadingPrintContent: true,

      settings: {
        letterSpacing: 0,
        wordSpacing: 0,
        lineHeight: 1.5,
        fontType: 'NUNITO',
        fontSize: undefined,
        showIllustrations: true,
      },

      slideIndex: (() => {
        const slideQuery = this.$route.query.slide;
        const number = parseInt(slideQuery);
        return isNaN(number) ? 0 : number;
      })(),
    };
  },

  computed: {
    isPrintMode() {
      return this.$route.query.isPrintMode === 'true';
    },
    readingComprehension() {
      return this.card?.addReadingComprehension
        ? this.card?.readingComprehension
        : null;
    },

    showCloseButton() {
      return (
        this.previewMode && !!window.opener && !this.doShowReadingComprehension
      );
    },

    studentIds() {
      return this.$route.query.studentIds?.split(',') ?? [];
    },

    previewMode() {
      return ['SessionCardPreview', 'StudentParentCardExercise'].includes(
        this.$route.name,
      );
    },

    addFinishRemark() {
      return this.$route.name === 'StudentParentCardExercise';
    },

    printMode() {
      return this.$route.name === 'SessionCardPrint';
    },

    hasPreviousSlide() {
      return this.slideIndex > 0;
    },

    hasNextSlide() {
      return this.slideIndex < this.totalSlidesAmount - 1;
    },

    totalSlidesAmount() {
      return this.card?.slides?.length ?? 0;
    },

    currentCardName() {
      return [
        this.card?.title,
        this.get(this.slides[this.slideIndex], 'meta', 'name'),
      ]
        .filter(Boolean)
        .join(' - ');
    },

    slides() {
      return this.card?.slides;
    },

    cardCssSettings() {
      const getValue = (key) =>
        this.getCardSettingCssValue(key, this.settings[key] ?? 0);

      if (this.$route.name === 'SessionCardPrint') {
        return {
          fontFamily: this.$route.query.fontFamily,
          letterSpacing: this.$route.query.letterSpacing,
          lineHeight: this.$route.query.lineHeight,
          wordSpacing: this.$route.query.wordSpacing,
        };
      }

      return {
        fontFamily: this.getFontTypeCssValue(this.settings.fontType),
        letterSpacing: getValue('letterSpacing'),
        lineHeight: getValue('lineHeight'),
        wordSpacing: getValue('wordSpacing'),
      };
    },
  },

  watch: {
    fullscreen: {
      handler({ toggled }) {
        const container = document.querySelector('.sb_session_scaled-content');
        if (toggled) {
          container?.requestFullscreen();
        } else if (document.fullscreenEnabled) {
          document.exitFullscreen().catch(() => null);
        }
      },
      deep: true,
    },

    card(value, prevValue) {
      if (!value) return;
      const audioUris = value.slides
        .reduce(
          (uris, slide) =>
            uris.concat(slide.audioFile, slide.audioFileSlowTrack),
          [],
        )
        .filter(Boolean);

      this.audioController.load(...audioUris);

      if (!prevValue && this.printMode && this.totalSlidesAmount > 0) {
        this.autoPrint(value);
      }

      document.title = [value.title, 'Leeshelden'].filter(Boolean).join(' - ');
    },

    slideIndex: {
      handler(value, prevValue) {
        if (value == prevValue || this.$route.query.slide == value) return;
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, slide: value },
        });
      },
      immediate: true,
    },
  },

  provide() {
    const self = this;
    return {
      settings: this.settings,
      slides: this.slides,
      slideIndex: this.slideIndex,
      getParentView: () => self,
    };
  },

  async mounted() {
    if (!this.printMode) {
      document
        .querySelector('.sb_session_scaled-content')
        .addEventListener('fullscreenchange', () => {
          if (document.fullscreenElement) {
            this.fullscreen.toggled = true;
          } else {
            this.fullscreen.toggled = false;
          }
        });

      window.addEventListener('keydown', this.handleKeyDown);
    }

    if (this.printMode) {
      document.body.classList.add('card-print-mode');
      this.doShowReadingComprehension = this.$route.query.doShowReadingComprehension;
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    const currentTourKey = 'COACH_CARD';
    this.enqueueTour(currentTourKey);
  },

  destroyed() {
    document.title = 'Leeshelden';

    removeEventListener('keydown', this.handleKeyDown);

    if (this.printMode) {
      document.body.classList.remove('card-print-mode');
    }
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    handleClose() {
      window.close();
    },

    showInstructions() {
      this.showMetaModal = !this.showMetaModal;
    },

    getCardNameByIndex(slideIndex) {
      return [
        this.card?.title,
        this.get(this.slides[slideIndex], 'meta', 'name'),
      ]
        .filter(Boolean)
        .join(' - ');
    },

    printCard() {
      if (this.$route.name === 'SessionCardPrint') {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerText = '@page { size: A4 landscape; }';
        document.head.append(style);
        return window.print();
      }

      const print = (assertSettings) => {
        window.open(
          this.$router.resolve({
            name: 'SessionCardPrint',
            query: {
              cardId: this.$route.query.cardId,
              slide: this.slideIndex,
              ...(assertSettings && this.cardCssSettings),
              ...(assertSettings && {
                showIllustrations: this.settings.showIllustrations,
              }),
              doShowReadingComprehension: this.doShowReadingComprehension,
              isPrintMode: true,
            },
          }).href,
          '_blank',
        );
      };

      this.$Modal.confirm({
        title: 'Printen',
        content: 'Wil je de huidige kaart-instellingen doorvoeren?',
        okText: 'Ja',
        cancelText: 'Nee',
        onCancel: () => print(false),
        onOk: () => print(true),
      });
    },

    toggleFullScreen() {
      if (!this.fullscreen) return;
      this.fullscreen.toggled = !this.fullscreen.toggled;
    },

    handleKeyDown({ key }) {
      if (key === 'ArrowRight') return this.nextSlide();
      if (key === 'ArrowLeft') return this.previousSlide();
    },

    nextSlide() {
      this.slideIndex = Math.min(
        this.totalSlidesAmount - 1,
        this.slideIndex + 1,
      );
    },

    previousSlide() {
      this.slideIndex = Math.max(0, this.slideIndex - 1);
    },

    handleMetaClick({ description }) {
      if (!description?.title || !description?.text) return;
      this.showMetaModal = true;
    },

    PREVIEW_nextSlide() {
      const isLastSlide =
        this.totalSlidesAmount === 0 ||
        this.slideIndex === this.totalSlidesAmount - 1;
      const doAddFinishRemark = this.addFinishRemark && isLastSlide;

      if (doAddFinishRemark) {
        this.$router.push({
          name: 'StudentParentCardExerciseFinish',
          query: objectOmit(this.$route.query, 'slide'),
        });
      } else this.nextSlide();
    },

    PREVIEW_previousSlide() {
      this.previousSlide();
    },

    async autoPrint(card, awaitAssets = true) {
      const imageAssets = card.slides
        .reduce((assets, slide) => {
          assets.push(slide.content?.imageLeft);
          assets.push(slide.content?.imageRight);
          assets.push(slide.content?.image);
          assets.push(slide.content?.featuredImage);
          assets.push(slide.content?.pronunciationIcon);
          return assets;
        }, [])
        .filter(Boolean);

      if (awaitAssets) {
        await Promise.all(
          imageAssets.map((url) => {
            return new Promise((resolve) => {
              const image = new Image();
              image.src = url;
              image.onload = resolve;
            });
          }),
        );
      }

      if (document.readyState !== 'complete') {
        console.log('retry');
        // try again after one second
        return window.setTimeout(() => this.autoPrint(card, false), 1000);
      }

      // set a timeout to ensure proper rendering (e.g. font rendering) & print on next animation frame to make sure our page looks as intended
      window.setTimeout(() => {
        requestAnimationFrame(() => this.printCard());
        this.loadingPrintContent = false;
      }, 1000);
    },
  },

  apollo: {
    card: {
      query() {
        return createSessionCardQuery(this.$route.query.cardId);
      },

      update(data) {
        if (this.slideIndex >= data.getCardById.slides.length) {
          this.slideIndex = 0;
        }

        return data.getCardById;
      },
    },
  },
};

const CARDS_AMOUNT = 8;
</script>

<style lang="scss">
.sb_session_practise-card_bottom {
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>

<style lang="scss" scoped>
.reading-comprehension-btn {
  svg {
    transform: scale(1.3) !important;
  }

  &.s_active {
    color: $brand-white !important;
    background: $brand-primary !important;
  }
}

.sb_cards-session_reading-comprehension_toggle {
  --size: 50px;
  position: absolute;
  right: 0;
  top: 50%;
  height: var(--size);
  width: var(--size);
  border-top-left-radius: calc(var(--size) / 2);
  border-bottom-left-radius: calc(var(--size) / 2);
  transform: translateY(-50%);
  color: $brand-primary;
  background: $brand-white;
  border: 2px solid $brand-primary;
  border-right-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: grid;
  place-items: center;
}

.sb_cards-session_reading-comprehension-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.sb_cards-session_training {
  position: relative;
  display: grid;
  min-height: 0;

  &.v_reading-comprehension {
    grid-template-columns: 1fr min-content;
  }
}

.sb_card-preview {
  height: 100vh;

  &_navigation {
    position: absolute;
    display: flex;
    gap: 20px;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translate(-40%, 50%);

    & button {
      min-width: 120px;
    }
  }
}

.sb_card-print {
  height: 100vh;
  font-size: 20px;
}

.sb_print-spacer {
  height: 1px;

  @media print {
    display: none;
  }
}

.sb_print-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  min-height: 300px;

  @media print {
    display: none;
  }
}

.sb_print-card {
  height: 100vh;
  overflow: hidden;

  @media print {
    height: auto;
    overflow: unset;
  }
}

.close-button {
  position: fixed;
  padding: 10px;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.font-large {
  font-size: 24px;
}
</style>
