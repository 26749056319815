<template>
  <div
    id="drag-box"
    class="sb_session-card_type-7"
    :class="{ [WORD_CONTAINER_TAG]: true }"
    :font-size="
      parseFontSize(
        settings.fontSize != undefined
          ? settings.fontSize
          : object.settings.fontSize,
      )
    "
  >
    <div class="sb_session-card_type-7_inner">
      <div ref="draggables" class="sb_session-card_type-7_inner_container">
        <div class="sb_session-card_type-7_items">
          <button
            v-for="(item, index) in questions"
            :key="index"
            class="sb_session-card_type-7_item draggable"
            :data-content="item.text"
          >
            <div class="sb_session-card_type-7_item_text" v-html="item.text" />
          </button>
        </div>

        <div ref="droppables" class="sb_session-card_type-7_items">
          <button
            v-for="(item, index) in answers"
            :key="index"
            class="sb_session-card_type-7_item"
            :data-content="item.text"
          >
            <div class="sb_session-card_type-7_item_image">
              <img
                v-if="item.image"
                :src="item.image"
                alt=""
                class="sb_session-card_type-7_item_image_img"
              />
            </div>

            <div class="content" v-html="item.text" />

            <div class="icon">
              <sb-icon icon-id="icon_check" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';

export default {
  mixins: [cardSettingsRulesMixin],
  props: {
    object: { type: Object, required: true },
  },
  inject: ['settings'],

  data() {
    return {
      questions: _.shuffle(this.object.content.words),
      answers: _.shuffle(this.object.content.words),
    };
  },

  mounted() {
    const self = this;
    const droppables = Array.from(this.$refs.droppables.children);
    const overlapThreshold = '50%';

    Draggable.create('.draggable', {
      type: 'x,y',
      edgeResistance: 0.65,
      bounds: '#drag-box',
      inertia: true,

      onDrag() {
        for (let child of droppables) {
          if (this.hitTest(child, overlapThreshold)) {
            if (!child.classList.contains('s_is-correct')) {
              return child.classList.add('s_drag-over');
            }
          } else {
            child.classList.remove('s_drag-over');
          }
        }
      },

      onDragEnd() {
        for (let child of droppables) {
          if (
            this.hitTest(child, overlapThreshold) &&
            !child.classList.contains('s_is-correct') &&
            child.dataset.content === this.target.dataset.content
          ) {
            gsap.to(this.target, { opacity: 0, pointerEvents: 'none' });
            child.classList.remove('s_drag-over');
            child.classList.add('s_is-correct');

            if (
              droppables.every((child) =>
                child.classList.contains('s_is-correct'),
              )
            ) {
              self.$Modal.success({
                title: 'Goed gedaan!',
              });
            }
            return;
          }
        }

        gsap.to(this.target, { x: 0, y: 0 });
      },
    });
  },

  methods: {},
};
</script>

<style lang="scss">
.sb_session-card_type-7 {
  padding: 40px;
  overflow: auto;
  height: 100%;

  //
  //  INNER
  //
  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column-gap: 200px;
    min-height: 730px;

    &_container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // align-content: flex-start;
      grid-gap: 300px;
    }
  }

  //
  //  ITEMS
  //
  &_items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: flex-start;
    grid-gap: 20px;
  }

  //  ITEM
  &_item {
    border-radius: 8px;
    border: solid 2px $brand-light-gray;
    height: 155px;
    display: grid;
    align-items: center;
    user-select: none;
    transition: border-color 0.15s;
    letter-spacing: inherit;
    background: #fff;
    position: relative;

    & .icon {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-35%, -35%);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: $brand-green;
      color: white;
      font-size: 28px;
      box-shadow: 0 0 10px $brand-green;
    }

    & .content {
      display: flex;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: $default-border-radius;
      background: $brand-white;
      justify-content: center;
      align-items: center;
      transition: 0.3s opacity;
    }

    &.draggable {
      cursor: move !important;
    }

    p {
      margin: 0 !important;
    }

    //  ITEM - TEXT
    &_text {
      text-align: center;
      font-weight: $semibold;

      strong {
        color: $brand-primary;
      }
    }

    //  ITEM - IMAGE
    &_image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 155px;
      pointer-events: none;
      &_img {
        width: 155px;
        height: 155px;
        object-fit: contain;
        object-position: center;
        transform: scale(0.95);
      }
    }

    //
    // STATE - ITEM - HOVER
    //
    &:hover {
      border-color: $brand-dark-gray;
    }

    //
    // STATE - ITEM - SELECTED
    //
    &.s_is-selected {
      border-color: $brand-primary;
    }

    //
    // STATE - ITEM - CORRECT
    //
    &.s_is-correct {
      border-color: $brand-green;

      & .icon {
        display: flex;
      }

      &:active {
        & .content {
          opacity: 1;
        }
      }
    }

    //
    // STATE - ITEM - INCORRECT
    //
    &.s_is-incorrect {
      border-color: $brand-red;
    }

    &.s_drag-over {
      border-color: $brand-primary;
    }
  }

  //
  //  FONT SIZE
  //
  &[font-size='small'] {
    font-size: 28px;
  }
  &[font-size='regular'] {
    font-size: 32px;
  }
  &[font-size='large'] {
    font-size: 40px;
  }
}
</style>
