<template>
  <div class="sb_session-card_image">
    <img :src="object.content.image" alt="" class="sb_session-card_image_img" />
  </div>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.sb_session-card_image {
  //
  // IMG
  //
  &_img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 1440px;
    height: 810px;
    object-fit: contain;
    object-position: center;
    user-select: none;
  }
}
</style>
