<template>
  <div
    class="sb_cards-session_reading-comprehension-column"
    :style="{ width: defaultWidth }"
    :data-print="dataPrint"
  >
    <div class="row-in-2 col-2" style="max-height: 100%; overflow: auto">
      <slot />
    </div>
    <div
      ref="resizer"
      class="sb_cards-session_reading-comprehension-column_resizer"
    >
      <div
        ref="resizerKnob"
        class="sb_cards-session_reading-comprehension-column_resizer_knob"
      >
        <sb-icon icon-id="icon_caret-left" />
        <sb-icon icon-id="icon_caret-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { TouchState } from '@/lib/touch-controller';
import SbIcon from '@/components/global/SbIcon.vue';
import { isTouchDevice } from '@/lib/is-touch-device';

export default {
  components: { SbIcon },

  props: {
    dataPrint: { type: Boolean, default: false },
  },

  created() {
    this.defaultWidth = isTouchDevice() ? '260px' : '400px';
  },

  mounted() {
    const parentElement = this.$el.parentElement;
    const resizer = this.$refs.resizer;
    const resizerTouchState = new TouchState(resizer);
    const knobTouchState = new TouchState(this.$refs.resizerKnob);
    const globalTouchState = new TouchState(parentElement);
    const computedStyle = getComputedStyle(this.$el);
    const minWidth = parseInt(computedStyle.minWidth || '0');
    let columnWidth = parseInt(computedStyle.width || '0');
    let resizedWidth = columnWidth;
    let isTouching = false;

    const handleTouchStart = () => {
      isTouching = true;
      parentElement.style.userSelect = 'none';
    };

    const handleTouchEnd = () => {
      isTouching = false;
      parentElement.style.userSelect = '';
      columnWidth = parseInt(getComputedStyle(this.$el).width || '0');
    };

    resizerTouchState.addEventListener('start', handleTouchStart);
    resizerTouchState.addEventListener('end', handleTouchEnd);
    knobTouchState.addEventListener('start', handleTouchStart);
    knobTouchState.addEventListener('end', handleTouchEnd);

    globalTouchState.addEventListener('move', (state, event) => {
      event.preventDefault();
      if (!isTouching) return;
      const { distance, direction } = state.horizontal;
      resizedWidth =
        columnWidth + (direction === 'right' ? -distance : distance);

      if (resizedWidth < minWidth) {
        globalTouchState.destroy();
        this.destroy();
        this.$emit('close');
      }
    });

    this.tick(() => (this.$el.style.width = resizedWidth + 'px'));
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    destroy() {
      cancelAnimationFrame(this.raf);
    },

    tick(cb) {
      cb?.();
      this.raf = requestAnimationFrame(() => this.tick(cb));
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_reading-comprehension-column {
  --resizer-width: 2px;

  &[data-print='true'] {
    display: none;
  }

  @media print {
    display: none;
  }

  position: relative;
  height: 100%;
  min-height: 0;
  min-width: 180px;
  max-width: 50vw;
  padding-left: var(--resizer-width);

  &_resizer {
    position: absolute;
    width: var(--resizer-width);
    left: 0;
    top: 0;
    bottom: 0;
    background: $brand-primary;
    cursor: col-resize;

    &_knob {
      display: flex;
      width: 30px;
      height: 30px;
      font-size: 0.7rem;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translate(calc(-50% + calc(var(--resizer-width) * 0.5)), -50%);
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $brand-gray;
      background: $brand-white;
      cursor: col-resize;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
