import gql from 'graphql-tag';

export function createSessionCardQuery(cardId) {
  return gql`
    query GetCardById {
      getCardById(id: ${cardId}) {
        id
        title
        type
        level
        number
        thumbnail
        instruction
        instructionTitle
        slides {
          name
          title
          text
          audioFile
          audioFileSlowTrack
          settings {
            fontSize
            columns
            featuredImageAlignment
            featuredImagePosition
            contentImageRatio
          }
          content {
            ... on CardSlideContentType1 {
              title
              text
              pronunciationIcon
              imageLeft
              imageRight
            }

            ... on CardSlideContentType2 {
              image
            }

            ... on CardSlideContentType3 {
              title
              sentences
              featuredImage
            }

            ... on CardSlideContentType4 {
              table
              featuredImage
            }

            ... on CardSlideContentType5 {
              table
              pronunciationIcon
              featuredImage
            }

            ... on CardSlideContentType6 {
              featuredLetter
              words {
                text
                image
              }
            }

            ... on CardSlideContentType7 {
              words {
                text
                image
              }
            }
            
            ... on CardSlideContentType8 {
              title
              featuredImage
              items {
                textLeft
                textRight
              }
            }
            __typename
          }
        }
        addReadingComprehension
        readingComprehension {
          instruction
          questions {
            instruction
            question
          }
        }
      }
    }
  `;
}
