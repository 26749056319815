var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sb_session-card_type-8",class:( _obj = {}, _obj[_vm.WORD_CONTAINER_TAG] = true, _obj ),attrs:{"font-size":_vm.parseFontSize(
      _vm.settings.fontSize != undefined
        ? _vm.settings.fontSize
        : _vm.object.settings.fontSize
    ),"image-position":_vm.object.settings.featuredImagePosition,"image-alignment":_vm.object.settings.featuredImageAlignment,"content-image-ratio":_vm.object.settings.contentImageRatio}},[_c('div',{staticClass:"sb_session-card_type-8_content",style:({ padding: _vm.calculateContentPadding() })},[_c('div',{staticClass:"sb_session-card_type-8_content_inner"},[(_vm.object.content.title)?_c('div',{staticClass:"sb_session-card_type-8_title"},[_vm._v(" "+_vm._s(_vm.object.content.title)+" ")]):_vm._e(),_c('div',{staticClass:"sb_session-card_type-8_items",style:({
          columns: _vm.object.settings.columns,
        })},_vm._l((_vm.object.content.items),function(item,i){return _c('div',{key:("item-" + i),staticClass:"sb_session-card_type-8_item"},[_c('div',{staticClass:"sb_session-card_type-8_item_text-left",domProps:{"innerHTML":_vm._s(item.textLeft)}}),_c('div',{staticClass:"sb_session-card_type-8_item_text-right",domProps:{"innerHTML":_vm._s(item.textRight)}})])}),0)])]),(_vm.object.content.featuredImage && _vm.showIllustrations)?_c('div',{staticClass:"sb_session-card_type-8_image"},[_c('img',{staticClass:"sb_session-card_type-8_image_img",attrs:{"src":_vm.object.content.featuredImage,"alt":""}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }