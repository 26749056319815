<template>
  <div
    class="sb_session-card_type-6"
    :class="{ [WORD_CONTAINER_TAG]: true }"
    :font-size="
      parseFontSize(
        settings.fontSize != undefined
          ? settings.fontSize
          : object.settings.fontSize,
      )
    "
  >
    <div class="sb_session-card_type-6_inner">
      <div
        v-if="object.content.featuredLetter"
        class="sb_session-card_type-6_featured-letter"
      >
        {{ object.content.featuredLetter }}
      </div>
      <!-- We can calculate the columns, based on the table layout -->
      <div
        class="sb_session-card_type-6_items"
        style="grid-template-columns: repeat(3, 1fr)"
      >
        <div
          v-for="(item, i) in object.content.words"
          :key="`item-${i}`"
          class="sb_session-card_type-6_item"
        >
          <div
            class="sb_session-card_type-6_item_text"
            v-html="item.text"
          ></div>
          <div class="sb_session-card_type-6_item_image">
            <img
              v-if="item.image && showIllustrations"
              :src="item.image"
              alt=""
              class="sb_session-card_type-6_item_image_img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { wordDefinitionsMixin } from '@/views/Default/SessionCards/word-definitions.mixin';

export default {
  mixins: [
    cardSettingsRulesMixin,
    wordDefinitionsMixin('object', '.sb_session-card_type-6_items'),
  ],
  props: {
    showIllustrations: { type: Boolean, default: true },
    object: { type: Object, default: () => {} },
  },
  inject: ['settings'],
};
</script>

<style lang="scss">
.sb_session-card_type-6 {
  padding: 40px;
  overflow: auto;
  height: 100%;

  //
  //  INNER
  //
  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 730px;
  }

  //
  //  FEATURED LETTER
  //
  &_featured-letter {
    border-radius: 8px;
    border: solid 2px $brand-light-gray;
    text-align: center;
    margin: 0 0 20px 0;
    padding: 10px 0;
    font-size: 60px;
  }

  //
  //  ITEMS
  //
  &_items {
    display: grid;
    grid-column-gap: 100px;
    grid-row-gap: 20px;
  }

  //
  //  ITEM
  //
  &_item {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    border: solid 2px $brand-light-gray;

    //  ITEM - TEXT
    &_text {
      text-align: center;
      font-weight: $semibold;
      p {
        margin: 0 !important;
      }
      strong {
        color: $brand-primary;
      }
    }
    //  ITEM - IMAGE
    &_image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 155px;
      &_img {
        width: 155px;
        height: 155px;
        object-fit: contain;
        object-position: center;
        transform: scale(0.85);
      }
    }
  }

  //
  //  FONT SIZE
  //
  &[font-size='small'] {
    font-size: 28px;
  }
  &[font-size='regular'] {
    font-size: 32px;
  }
  &[font-size='large'] {
    font-size: 40px;
  }
}
</style>
