<template>
  <div class="sb_reading-comprehension">
    <div
      class="sb_reading-comprehension_instruction row-2"
      v-html="get(readingComprehension, 'instruction')"
    />

    <div
      v-for="(question, index) in get(readingComprehension, 'questions')"
      :key="index"
      class="sb_reading-comprehension_qa"
    >
      <div class="row-2">
        <p>
          <b>Vraag {{ index + 1 }}</b>
        </p>
        <div v-html="get(question, 'question')" />
        <i-button
          v-if="!!get(question, 'instruction')"
          size="small"
          @click="toggleInstructions(index)"
        >
          Tip
          {{ toggledInstructionIndex === index ? 'verbergen' : 'weergeven' }}
        </i-button>
      </div>

      <div
        v-if="toggledInstructionIndex === index"
        class="row-2"
        v-html="get(question, 'instruction')"
      />

      <template v-if="!!$route.query.exerciseId || !!$route.query.exerciseIds">
        <i-input
          v-model="answers[index]"
          class="row-2"
          type="textarea"
          :placeholder="`Antwoord op vraag ${index + 1}`"
          :disabled="$apollo.queries.getAnswers.loading"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import gql from 'graphql-tag';
import { debounce } from 'lodash';

export default {
  props: { readingComprehension: { type: Object, default: undefined } },
  data() {
    return {
      toggledInstructionIndex: undefined,
      answers: this.readingComprehension?.questions.map(
        (question) =>
          this.$store.state.readingComprehensionQALookup[question.question],
      ),
    };
  },

  inject: [],

  computed: {
    exerciseIds() {
      return (
        this.$route.query.exerciseIds?.split(',') ||
        [this.$route.query.exerciseId].filter(Boolean)
      );
    },
  },

  watch: {
    answers(value) {
      this.readingComprehension?.questions.forEach((question, index) => {
        const exists =
          question.question in this.$store.state.readingComprehensionQALookup;

        if (!exists) {
          Vue.set(
            this.$store.state.readingComprehensionQALookup,
            question.question,
            '',
          );
        }

        this.$store.state.readingComprehensionQALookup[question.question] =
          value[index];
      });
    },

    readingComprehension: {
      immediate: true,
      handler(value) {
        requestAnimationFrame(() => {
          const anchors = this.$el.querySelectorAll('a');
          anchors.forEach((anchor) => anchor.setAttribute('target', '_blank'));
        });

        if (!value) return this.unwatchQA?.forEach((unwatch) => unwatch());
        this.unwatchQA = value.questions.map(
          (_, index) => (
            (this.answers[index] = undefined),
            this.$watch(
              () => this.answers[index],
              () => void this.submitAnswers(),
            )
          ),
        );
      },
    },
  },

  methods: {
    toggleInstructions(index) {
      this.toggledInstructionIndex =
        this.toggledInstructionIndex === index ? null : index;
    },

    submitAnswers: debounce(async function debouncedSubmitAnswers() {
      const upsert = this.readingComprehension?.questions
        .map((question, index) => ({
          question: question.question,
          answer: this.answers[index],
        }))
        .filter((e) => [e.question, e.answer].every(Boolean));

      if (!this.exerciseIds?.length || !upsert?.length) return;

      try {
        const aliases = this.exerciseIds.map((exerciseId) => {
          return `${exerciseId}: updateCardExercise(input: $input) { id }`;
        });

        const results = await Promise.all(
          this.exerciseIds.map((exerciseId) =>
            this.$apollo.mutate({
              variables: {
                input: {
                  id: exerciseId,
                  data: {
                    readingComprehensionAnswers: { upsert },
                  },
                },
              },

              mutation: gql`
                mutation ReadingComprehension_UpdateCardExercise($input: UpdateCardExerciseInput!) {
                  ${aliases}
                }
              `,
            }),
          ),
        );

        this.$Message.success('Antwoorden opgeslagen');
        return results;
      } catch (error) {
        console.error(error);
      }
    }, 1000),
  },

  apollo: {
    getAnswers: {
      variables() {
        return { filter: { id: { in: this.exerciseIds } } };
      },

      update(data) {
        const ref = data.cardExercises[0];
        if (!ref) return;

        ref.readingComprehensionAnswers.forEach((question) => {
          this.$store.state.readingComprehensionQALookup[question.question] =
            question.answer;

          this.answers = this.readingComprehension?.questions.map(
            (question) =>
              ref.readingComprehensionAnswers.find(
                (answer) => answer.question === question.question,
              )?.answer,
          );
        });
      },

      query: gql`
        query ReadingComprehension_GetAnswers($filter: CardExercisesFilter!) {
          cardExercises(filter: $filter) {
            id
            readingComprehensionAnswers {
              question
              answer
            }
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_reading-comprehension {
  padding-left: 0.5rem;

  ::v-deep {
    ol,
    ul {
      padding-left: 1rem;
    }
  }

  &_instruction {
  }

  &_qa {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    &:nth-child(even) {
      background: $brand-primary-lightest;
    }
  }
}
</style>
