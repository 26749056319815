export const slideCalculateContentPaddingMixin = {
  methods: {
    calculateContentPadding() {
      let [, paddingValue] = (
        this.object.settings.contentImageRatio ?? ''
      ).split('-');

      if (paddingValue == undefined) {
        paddingValue = '25';
      }

      if (
        this.object.content.featuredImage == undefined ||
        this.showIllustrations === false
      ) {
        paddingValue = '60px';
      } else {
        // add a bit (2%) of additional space, if left
        if (this.object.settings.featuredImagePosition === 'left') {
          paddingValue = parseInt(paddingValue) + 2 + '%';
        } else {
          paddingValue = parseInt(paddingValue) + '%';
        }
      }

      const paddingValues =
        this.object.settings.featuredImagePosition === 'left'
          ? `40px 60px 40px ${paddingValue}`
          : `40px ${paddingValue} 40px 60px`;

      return paddingValues;
    },
  },
};
