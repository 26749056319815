<template>
  <div
    class="sb_session-card_type-4"
    :class="{ [WORD_CONTAINER_TAG]: true }"
    :font-size="
      parseFontSize(
        settings.fontSize != undefined
          ? settings.fontSize
          : object.settings.fontSize,
      )
    "
    :image-position="object.settings.featuredImagePosition"
    :image-alignment="object.settings.featuredImageAlignment"
    :content-image-ratio="object.settings.contentImageRatio"
    :style="{ padding: calculateContentPadding() }"
  >
    <div class="sb_session-card_type-4_inner">
      <div
        class="sb_session-card_type-4_items"
        :style="{
          gridTemplateColumns: `repeat(${columnsAmount}, 1fr)`,
          ...(columnsAmount <= 3
            ? {
                margin: '0 auto',
                minWidth: '60%',
              }
            : undefined),
        }"
      >
        <div
          v-for="(item, i) in items"
          :key="`item-${i}`"
          class="sb_session-card_type-4_item"
          v-html="item"
        />
      </div>
    </div>
    <div
      v-if="object.content.featuredImage && showIllustrations"
      class="sb_session-card_type-4_image"
    >
      <img
        :src="object.content.featuredImage"
        alt=""
        class="sb_session-card_type-4_image_img"
      />
    </div>
  </div>
</template>

<script>
import { slideCalculateContentPaddingMixin } from './slideCalculateContentPaddingMixin';
import { parseHtmlTableString } from '@/lib/parseHtmlTableString';
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { wordDefinitionsMixin } from '@/views/Default/SessionCards/word-definitions.mixin';

export default {
  mixins: [
    slideCalculateContentPaddingMixin,
    cardSettingsRulesMixin,
    wordDefinitionsMixin(
      'object.content.table',
      '.sb_session-card_type-4_items',
    ),
  ],

  props: {
    showIllustrations: { type: Boolean, required: false },
    object: { type: Object, required: true },
  },

  inject: ['settings'],

  data() {
    const tableData = parseHtmlTableString(
      this.object.content?.table,
      (valid) => {
        if (this.$user.context.isAdmin && !valid) {
          this.$Modal.warning({
            title: 'Geen tabel gevonden',
            content:
              'Dit type kaart verwacht een tabel als databron. Controleer de content in het beheersysteem.',
          });
        }
      },
    );

    return {
      columnsAmount: tableData.columnsAmount,
      items: tableData.contents,
    };
  },
};
</script>

<style lang="scss">
.sb_session-card_type-4 {
  padding: 40px 100px;
  overflow: auto;
  height: 100%;

  //
  //  INNER
  //
  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 730px;
  }

  //
  //  ITEMS
  //
  &_items {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }

  //
  //  ITEM
  //
  &_item {
    min-height: 24px;
    strong {
      color: $brand-primary;
    }
  }

  //
  //  IMAGE
  //
  &_image {
    pointer-events: none;
    right: 0;
    width: 25%;
    max-width: 25%;
    position: absolute;
    top: 0;
    height: 100%;

    &_img {
      position: absolute;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);
      right: 2rem;
      bottom: 2rem;
      object-fit: contain;
      object-position: bottom;
    }
  }

  //
  //  FONT SIZE VARIATIONS
  //
  @include slideFontSizes();

  //
  //  IMAGE POSITION VARIATIONS
  //
  @include slideImage('4');
}
</style>
