<template>
  <div class="sb_session-card_type-1" :class="{ [WORD_CONTAINER_TAG]: true }" :font-size="
    parseFontSize(
      settings.fontSize != undefined
        ? settings.fontSize
        : object.settings.fontSize,
    )
  ">
    <div :class="`${textContentClass} sb_session-card_type-1_container`">
      <div class="sb_session-card_type-1_container_inner">
        <div v-if="object.content.title" class="sb_session-card_type-1_title">
          {{ object.content.title }}
        </div>
        <div class="sb_session-card_type-1_content" :class="{ 's_has-image-right': object.content.imageRight != null }">
          <div v-if="object.content.imageLeft && showIllustrations" class="sb_session-card_type-1_content_image-left">
            <img :src="object.content.imageLeft" alt="" class="sb_session-card_type-1_content_image-left_img" />
          </div>
          <div class="sb_session-card_type-1_content_text">
            <div v-if="object.content.text" ref="content" class="sb_session-card_type-1_content_text-content"
              v-html="object.content.text" />
            <img v-if="object.content.pronunciationIcon" :src="object.content.pronunciationIcon" alt=""
              class="sb_session-card_type-1_content_pronunciation-icon" />
          </div>
          <div v-if="object.content.imageRight && showIllustrations" class="sb_session-card_type-1_content_image-right">
            <img :src="object.content.imageRight" alt="" class="sb_session-card_type-1_content_image-right_img" />
          </div>
        </div>
        <div v-if="object.content.subtext" class="sb_session-card_type-1_subtext">
          {{ object.content.subtext }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { wordDefinitionsMixin } from '@/views/Default/SessionCards/word-definitions.mixin';
export default {
  mixins: [
    cardSettingsRulesMixin,
    wordDefinitionsMixin(
      'object',
      '.sb_session-card_type-1_content_text-content',
    ),
  ],

  props: {
    showIllustrations: { type: Boolean, default: true },
    object: { type: Object, required: true },
  },
  data() {
    return {
      textContentClass: '',
    };
  },

  inject: ['settings'],
  computed: {
    lineHeight() {
      return this.$store.state.lineHeight;
    },
    fontValue() {
      return this.$store.state.fontValue;
    },
    letterSpacing() {
      return this.$store.state.letterSpacing;
    },
  },
  watch: {
    lineHeight() {
      this.validateTextContent();
    },
    fontValue() {
      this.validateTextContent();
    },
    letterSpacing() {
      this.validateTextContent();
    },
  },

  mounted() {
    this.$refs.content
      ?.getElementsByTagName('table')[0]
      ?.setAttribute('border', '0');
  },
  methods: {
    /**
     * Used to change flex alignment when text gets too big.
     */
    validateTextContent() {
      const textContent = this.$refs.content;
      if (textContent) {
        const WHEN_TEXT_WILL_OVERFLOW = 430;
        const textWillOverflow =
          textContent.offsetHeight > WHEN_TEXT_WILL_OVERFLOW;
        this.textContentClass = textWillOverflow ? 'overflow' : '';
      }
    },
  },
};
</script>

<style lang="scss">
$HEIGHT: 800px;

.sb_session-card_type-1 {
  position: relative;

  &_content {
    &_text-content {
      sup {
        top: unset;
        font-size: 1.25em;
        vertical-align: inherit;
        line-height: inherit;
        position: static;
      }
    }
  }

  //
  //  CONTAINER
  //
  &_container {
    padding: 40px 100px;
    overflow: hidden;

    //  CONTAINER - INNER
    &_inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 730px;

      &_big_text {
        justify-content: flex-start;
        /* padding-bottom: 400px; */

        /* &:before {
          position: absolute;
          content: '';
          display: block;
          clear: both;
          bottom: 0;
          right: 0;
          left: 0;
          height: 100px;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        } */
      }
    }
  }

  //
  //  TITLE
  //
  &_title {
    color: $brand-primary;
    font-weight: $bold;
    font-size: 24px;
    margin: 0 0 2em 0;
  }

  //
  //  CONTENT
  //
  &_content {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: flex-start;
    font-weight: $semibold;
    border-radius: 8px;

    //
    //  CONTENT - IMAGE - LEFT
    //
    &_image-left {
      position: relative;
      pointer-events: none;
      width: percentage(math.div(1, 3));
      max-width: percentage(math.div(1, 3));
      height: 100%;
      margin: auto 0;

      &_img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    //
    //  CONTENT - TEXT
    //
    &_text {
      width: percentage(math.div(2, 3));
      max-width: percentage(math.div(2, 3));
      padding: 0 40px;

      table {
        border: none;
      }

      strong {
        color: $brand-primary;
      }
    }

    //
    //  CONTENT - PRINUNCIATION ICON
    //
    &_pronunciation-icon {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center;
    }

    //
    //   CONTENT -  IMAGE - RIGHT
    //
    &_image-right {
      position: relative;
      pointer-events: none;

      width: percentage(math.div(1, 3));
      max-width: percentage(math.div(1, 3));
      height: 100%;

      &_img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    //
    // STATE - HAS IMAGE RIGHT
    //
    &.s_has-image-right {
      .sb_session-card_type-1_content_text {
        width: percentage(math.div(1, 3));
        max-width: percentage(math.div(1, 3));
      }
    }
  }

  //
  //  SUBTEXT
  //
  &_subtext {
    margin: 10px 0 0 0;
    font-size: 20px;
    max-width: 800px;
  }

  //
  //  FONT SIZE
  //
  &[font-size='small'] {

    .sb_session-card_type-1_title,
    .sb_session-card_type-1_content_text {
      font-size: 28px;
    }
  }

  &[font-size='regular'] {

    .sb_session-card_type-1_title,
    .sb_session-card_type-1_content_text {
      font-size: 32px;
    }
  }

  &[font-size='large'] {

    .sb_session-card_type-1_title,
    .sb_session-card_type-1_content_text {
      font-size: 40px;
    }
  }
}

.overflow {
  &.sb_session-card_type-1_container {
    padding: 40px 100px;
    overflow-y: scroll;

    //  CONTAINER - INNER
    .sb_session-card_type-1_container_inner {
      justify-content: flex-start;
    }

    /* &:after {
      position: absolute;
      content: 'Scroll naar beneden';
      bottom: 0;
      right: 0;
      transform: translateX(-30%);
    } */
  }
}
</style>
