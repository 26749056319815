<template>
  <div
    class="sb_session-card_type-3"
    :font-size="
      parseFontSize(
        settings.fontSize != undefined
          ? settings.fontSize
          : object.settings.fontSize,
      )
    "
    :image-position="object.settings.featuredImagePosition"
    :image-alignment="object.settings.featuredImageAlignment"
    :content-image-ratio="object.settings.contentImageRatio"
  >
    <div
      class="sb_session-card_type-3_content"
      :style="{ padding: calculateContentPadding() }"
    >
      <div class="sb_session-card_type-3_content_inner">
        <div v-if="object.content.title" class="sb_session-card_type-3_title">
          {{ object.content.title }}
        </div>

        <div
          :key="contentRenderKey"
          class="sb_session-card_type-3_sentences"
          :class="{
            's_line-numbers': doShowLineNumbers,
            [WORD_CONTAINER_TAG]: true,
          }"
          :style="{
            columns: object.settings.columns,
          }"
          v-html="object.content.sentences"
        />
      </div>
    </div>
    <div
      v-if="object.content.featuredImage && showIllustrations"
      class="sb_session-card_type-3_image"
    >
      <img
        :src="object.content.featuredImage"
        alt=""
        class="sb_session-card_type-3_image_img"
      />
    </div>
  </div>
</template>

<script>
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { wordDefinitionsMixin } from '@/views/Default/SessionCards/word-definitions.mixin';
import { slideCalculateContentPaddingMixin } from './slideCalculateContentPaddingMixin';
export default {
  mixins: [
    slideCalculateContentPaddingMixin,
    cardSettingsRulesMixin,
    wordDefinitionsMixin(
      'object.content.sentences',
      '.sb_session-card_type-3_sentences',
    ),
  ],

  props: {
    showIllustrations: { type: Boolean, default: true },
    object: { type: Object, default: undefined },
    allSlides: { type: Array, default: undefined },
  },

  inject: ['settings', 'slides'],

  data() {
    return { contentRenderKey: Date.now() };
  },

  computed: {
    doShowLineNumbers() {
      return this.$store.state.doShowLineNumbers;
    },
  },

  watch: {
    doShowLineNumbers: {
      immediate: true,
      handler(value) {
        document
          .querySelector('.sb_session-card_type-3_sentences')
          ?.classList[value ? 'add' : 'remove']('s_line-numbers');
        this.__wordDefinitionMixin__doWrapLines = value;
        this.resetWordDefinitionDOM();
      },
    },

    settings: {
      deep: true,
      handler() {
        this.resetWordDefinitionDOM();
      },
    },
  },

  created() {
    this.__wordDefinitionMixin__onDOMMutation = ({ noOfLines = 0 }) => {
      const slideIndex = this.allSlides?.indexOf(this.object);
      this.$parent.accumulatedNoOfLines[slideIndex] =
        noOfLines || this.$parent.accumulatedNoOfLines[slideIndex];
      this.$el.style.setProperty(
        'counter-set',
        `sentences ${this.getLineCountOffset()}`,
      );
    };
  },

  methods: {
    getLineCountOffset() {
      const slideIndex = this.allSlides?.indexOf(this.object);
      const total = this.$parent.accumulatedNoOfLines
        .slice(0, slideIndex)
        .filter(Boolean)
        .reduce((n, total) => total + n, 0);
      return total;
    },

    resetWordDefinitionDOM() {
      this.contentRenderKey = Date.now();
      requestAnimationFrame(() => this.prepareWordDefinitionDOM());
    },
  },
};
</script>

<style lang="scss">
.sb_session-card_type-3 {
  font-weight: $semibold;
  display: flex;
  height: 100%;

  //
  //  CONTENT
  //
  &_content {
    width: 100%;
    overflow: auto;

    // CONTENT - INNER
    &_inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 730px;
    }
  }

  //
  //  TITLE
  //
  &_title {
    color: $brand-primary;
    font-weight: $bold;
    padding: 15px 0;
    padding-left: 2rem;
  }

  //
  //  SENTENCES
  //
  &_sentences {
    column-gap: 60px;
    padding-left: 2rem;

    strong {
      color: $brand-primary;
    }

    &.s_line-numbers {
      .sb-dict-line::before {
        @keyframes animateIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        animation: animateIn 0.3s ease;
        animation-fill-mode: forwards;
      }
    }

    .sb-dict-line {
      display: block;
      position: relative;

      &::before {
        opacity: 0;
        counter-increment: sentences;
        content: counter(sentences);
        color: $brand-light-gray;
        font-weight: 400;
        left: -1rem;
        position: absolute;
        transform: translateX(-100%);
      }
    }
  }

  //
  //  IMAGE
  //
  &_image {
    pointer-events: none;
    right: 0;
    width: 25%;
    max-width: 25%;
    position: absolute;
    height: 100%;

    &_img {
      position: absolute;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);
      right: 2rem;
      bottom: 2rem;
      object-fit: contain;
      object-position: bottom;
    }
  }

  //
  //  FONT SIZE VARIATIONS
  //
  @include slideFontSizes();

  //
  //  IMAGE POSITION VARIATIONS
  //
  @include slideImage('3');
}
</style>
