<template>
  <div class="sb_cards-session_training_instructions">
    <div
      class="sb_cards-session_training_instructions_background"
      :class="{ s_toggled: value }"
      @click="$emit('input', !value)"
    />

    <div
      class="sb_cards-session_training_instructions_inner"
      :class="{ s_toggled: value }"
    >
      <sb-spacer height="20" />
      <sb-title
        :text="get(card, 'instructionTitle') || get(card, 'title')"
        size="small"
      />

      <div
        class="sb_cards-session_training_instructions_inner_content"
        v-html="get(card, 'instruction')"
      />

      <div
        v-if="!!value && !$store.state.doShowWordDefinitionInfo"
        class="sb_cards-session_training_instructions_label"
        @click="$emit('input', !value)"
      >
        <!-- @click="handleMetaClick(slides[slideIndex].meta)" -->
        <sb-icon icon-id="icon_caret-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbCardsSessionMetaModal',
  props: {
    value: { type: Boolean, required: true },
    card: { type: Object, default: undefined },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_training_instructions {
  position: fixed;
  width: 0;
  right: 0;
  top: 0;
  height: 100vh;
  overflow: visible;
  z-index: 1000;

  // for related print settings on the <body>, check app.scss
  @media print {
    display: none;
  }

  &_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    transition: 0.3s background-color;
    z-index: 0;
    pointer-events: none;

    &.s_toggled {
      background: rgba($brand-black, 0.5);
      pointer-events: unset;
    }
  }

  &_inner {
    width: 480px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(calc(100% - 8px));
    background: $brand-white;
    overflow: visible;
    padding: 40px;
    transition: 0.3s transform, 0.3s box-shadow;

    &_content {
      overflow: auto;
      white-space: pre-line;
    }

    &.s_toggled {
      transform: translateX(-8px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  &_label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(calc(-100% + 8px), -50%);
    z-index: -1;
    padding-right: 8px;
    width: 48px;
    height: 60px;
    background: $brand-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $brand-primary;
    border-top-left-radius: $default-border-radius;
    border-bottom-left-radius: $default-border-radius;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &:hover {
      color: $brand-black;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      transition-duration: 0s;
    }

    &::after {
      content: ' ';
      position: absolute;
      right: -12px;
      width: 20px;
      height: 80px;
      top: -10px;
      background: $brand-white;
    }
  }
}
</style>
