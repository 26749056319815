<template>
  <div
    class="sb_session-card_type-8"
    :class="{ [WORD_CONTAINER_TAG]: true }"
    :font-size="
      parseFontSize(
        settings.fontSize != undefined
          ? settings.fontSize
          : object.settings.fontSize,
      )
    "
    :image-position="object.settings.featuredImagePosition"
    :image-alignment="object.settings.featuredImageAlignment"
    :content-image-ratio="object.settings.contentImageRatio"
  >
    <div
      class="sb_session-card_type-8_content"
      :style="{ padding: calculateContentPadding() }"
    >
      <div class="sb_session-card_type-8_content_inner">
        <div v-if="object.content.title" class="sb_session-card_type-8_title">
          {{ object.content.title }}
        </div>
        <div
          class="sb_session-card_type-8_items"
          :style="{
            columns: object.settings.columns,
          }"
        >
          <div
            v-for="(item, i) in object.content.items"
            :key="`item-${i}`"
            class="sb_session-card_type-8_item"
          >
            <div
              class="sb_session-card_type-8_item_text-left"
              v-html="item.textLeft"
            />
            <div
              class="sb_session-card_type-8_item_text-right"
              v-html="item.textRight"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="object.content.featuredImage && showIllustrations"
      class="sb_session-card_type-8_image"
    >
      <img
        :src="object.content.featuredImage"
        alt=""
        class="sb_session-card_type-8_image_img"
      />
    </div>
  </div>
</template>

<script>
import { cardSettingsRulesMixin } from '@/lib/card-settings-rules';
import { wordDefinitionsMixin } from '@/views/Default/SessionCards/word-definitions.mixin';
import { slideCalculateContentPaddingMixin } from './slideCalculateContentPaddingMixin';

export default {
  mixins: [
    slideCalculateContentPaddingMixin,
    cardSettingsRulesMixin,
    wordDefinitionsMixin('object', '.sb_session-card_type-8_items'),
  ],
  props: {
    showIllustrations: { type: Boolean, required: false },
    object: { type: Object, default: () => {} },
  },
  inject: ['settings'],
};
</script>

<style lang="scss">
.sb_session-card_type-8 {
  font-weight: $semibold;
  display: flex;
  height: 100%;

  //
  //  CONTENT
  //
  &_content {
    width: 100%;
    overflow: auto;

    // CONTENT - INNER
    &_inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 730px;
    }
  }

  //
  //  TITLE
  //
  &_title {
    color: $brand-primary;
    font-weight: $bold;
    padding: 15px 0;
  }

  //
  //  ITEMS
  //
  &_items {
  }

  //
  //  ITEM
  //
  &_item {
    display: grid;
    grid-template-columns: 25% 75%;
    font-weight: $semibold;
    p {
      margin: 0 !important;
    }
    strong {
      color: $brand-primary;
    }
    //  ITEM - TEXT LEFT
    &_text-left {
      padding: 0.5rem 0;
    }

    //  ITEM - TEXT RIGHT
    &_text-right {
      border-left: solid 3px $brand-primary;
      padding: 0.5rem 0 0.5rem 1.5rem;
    }
  }

  //
  //  IMAGE
  //
  &_image {
    pointer-events: none;
    right: 0;
    width: 25%;
    max-width: 25%;
    position: absolute;
    height: 100%;

    &_img {
      position: absolute;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);
      right: 2rem;
      bottom: 2rem;
      object-fit: contain;
      object-position: bottom;
    }
  }

  //
  //  FONT SIZE VARIATIONS
  //
  @include slideFontSizes();

  //
  //  IMAGE POSITION VARIATIONS
  //
  @include slideImage('8');
}
</style>
