/**
 * Parse an HTML string containing a table to get it's cell contents.
 * @param {String} html - HTML string containing a table element.
 * @returns {Object} Object with `columnsAmount` amount of columns, and `contents` cell content as a flat array of (HTML) strings.
 */
export function parseHtmlTableString(html, callback) {
  const container = document.createElement('div');

  container.innerHTML = html;
  callback?.(container.getElementsByTagName('table').length > 0);

  return typedChildArray(container, 'tr').reduce(
    (acc, $tr) => {
      const children = typedChildArray($tr, 'td');
      return {
        columnsAmount: Math.max(acc.columnsAmount, children.length),
        contents: [
          ...acc.contents,
          ...children.map(($td) => $td.innerHTML || ''),
        ],
      };
    },
    { columnsAmount: 0, contents: [] },
  );
}

function typedChildArray(container, type) {
  return Array.from(container.getElementsByTagName(type));
}
